.PlayerCard {
  max-width: 120px;
  min-width: 120px;
  margin: 10px;
  height: 150px;
  margin-top: 40px;
  width: 90%;
  overflow: initial;
  background-color: var(--color-background-secondary);
  margin-top: 40px;
  transition: 0.3s;
  border-radius: 4px;
}

.PlayerCardTitle {
  display: flex;
  text-align: center;
  border-radius: 16px;
  border: 2px solid var(--color-primary);
  background-color:  var(--color-background);
  margin: -20px auto 0;
  width: 88%;
  padding: 16px;
  height: 40px;
}

.RemoveButton{
  margin-left: -17px;
  margin-top: -20px;
}

.MuiCardHeader-content {
  width: 100%;
}
.PlayerCardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.PlayerCardContentMiddle {
  display: flex;
  justify-content: center;
}


